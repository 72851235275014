import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'fruit_snacks',
  formId: 'DK%20Lunch',
  title: 'Frugt & Snacks',
  subTitle: 'Livet er for kort til at gå sukkerkold',
  icon: '/icons/fruit.svg',
  image: '/assets/images/sunrise/fruit_snacks_supermercat.png',
  theme: 'sunrise-fruit_snacks',
  sections: [
    {
      sectionId: 'hero',
      label: 'Frugt & Snacks',
      title: 'Livet er for kort til at gå sukkerkold',
      description:
        ' Sprød og saftig frugt (fibre og vitaminer), knasende nødder (proteiner og olier), dejlige chokolader (antioxidanter og det der vidundelige sukkerløft)! Vi garanterer, at du bliver dagens helt når du rigger et møde til med snacks!',
      image: '/assets/images/sunrise/fruit_snacks_supermercat.png',
      icon: '/icons/fruit.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Alt hvad hjertet kan begære',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/fruit_snacks/service_1.svg',
          title: 'Sunde snacks',
          description: '',
        },
        {
          icon: '/assets/images/services/fruit_snacks/service_2.svg',
          title: 'Chokolade',
          description: '',
        },
        {
          icon: '/assets/images/services/fruit_snacks/service_3.svg',
          title: 'Fredagsslik?',
          description: '',
        },
        {
          icon: '/assets/images/services/fruit_snacks/service_4.svg',
          title: 'Frugt',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Det er så nemt',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/fruit_snacks/journey_1.svg',
          title: 'Fortæl os hvad du kan lide',
          description: 'Sig det bare. Vi dømmer dig ikke. ',
        },
        {
          icon: '/assets/images/services/fruit_snacks/journey_2.svg',
          title: 'Få et tilbud',
          description: 'Vi laver et detaljeret tilbud.',
        },
        {
          icon: '/assets/images/services/fruit_snacks/journey_3.svg',
          title: 'Ja tak!',
          description: 'Det var det. Er I klar til snackfest?',
        },
        {
          icon: '/assets/images/services/fruit_snacks/journey_4.svg',
          title: 'Knas, sut, guf!',
          description: 'Namnam. Så kan vi lidt igen.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor elsker vore kunder snacks fra Good Monday?',
      description: '',
      image: '/assets/images/services/fruit_snacks/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration til snacks og frugt',
          description: 'Vi kan få alt det, der findes. Lad os lave et mix sammen.',
        },
        {
          icon: 'eva eva-smiling-face-outline',
          title: 'Sunde alternativer',
          description: 'Vi har alt det, der er godt for dig!',
        },
        {
          icon: 'eva eva-activity-outline',
          title: 'Flexibilitet',
          description: 'Det er let at skrue på dit mix, når I trænger til noget nyt og spændende!',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du, at mange af vore kunder siger, at de spiser færre frække ting, når der er frugt på kontoret?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
